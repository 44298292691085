import React, { useRef } from "react";
import { useEffect, useState } from "react";

import axios from 'axios'

// import { ReactComponent as Emoji } from './images/emoji.svg';

import {
  loadUserUsdBalance,
  loadUserMbpBalance,
  loadUsdAllowance,
  approveUsdSpending,
  loadUserDksBalance,
  stakeMBPToken,
  stakeDKSToken,
  unstakeMBPToken,
  unstakeDKSToken,
  loadUserMBPAmountStaked,
  loadUserDKSAmountStaked,
  loadUserMBP_StakeDaysCount,
  loadUserDKS_StakeDaysCount,
  loadMinDaysToUnstake,
  loadMinMBPStake,
  loadMinDKSStake,
  loadTotalMBPAmountStaked,
  loadTotalDKSAmountStaked,
  loadNoUnstakeDays,
  loadUnstakeFee1,
  loadUnstakeFee2
} from "./util/interact.js";

///////////////////////////////////////////////////////////////////////////////////////////////////
import {
  useWeb3React,
  UnsupportedChainIdError
} from "@web3-react/core";
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected
} from "@web3-react/injected-connector";
import {
  URI_AVAILABLE,
  UserRejectedRequestError as UserRejectedRequestErrorWalletConnect
} from "@web3-react/walletconnect-connector";
import { UserRejectedRequestError as UserRejectedRequestErrorFrame } from "@web3-react/frame-connector";

import {
  injected,
  // network,
  walletconnect,
  // walletlink,
  // ledger,
  // trezor,
  // frame,
  // fortmatic,
  // portis,
  // squarelink,
  // torus,
  // authereum
} from "./connectors";
import { useEagerConnect, useInactiveListener } from "./hooks";
///////////////////////////////////////////////////////////////////////////////////////////////////




///////////////////////////////////////////////////////////////////////////////////////////////////
const connectorsByName = {
  Injected: injected,
  // Network: network,
  WalletConnect: walletconnect,
  // WalletLink: walletlink,
  // Ledger: ledger,
  // Trezor: trezor,
  // Frame: frame,
  // Fortmatic: fortmatic,
  // Portis: portis,
  // Squarelink: squarelink,
  // Torus: torus,
  // Authereum: authereum
};


function getErrorMessage(error) {

  if (error) {
    if (error instanceof NoEthereumProviderError) {
      return {
        status: "No BSC browser extension detected, install MetaMask on desktop or visit from a DApp browser on mobile.",
      };
    } else if (error instanceof UnsupportedChainIdError) {
      return {
        status: "You're connected to an unsupported network.",
      };
    } else if (
      error instanceof UserRejectedRequestErrorInjected ||
      error instanceof UserRejectedRequestErrorWalletConnect ||
      error instanceof UserRejectedRequestErrorFrame
    ) {
      return {
        status: "Please authorize this website to access your account.",
      };
    } else {
      return {
        status: "WalletConnect connection rejected by user.",
      };
    }
  } else {
    // console.error(error);
    return {
      status: "Welcome to the MobiPad Staking Page.",
    };
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////



const AppEngine = () => {

  ///////////////////////////////////////////////////////////////////////////////////////////////////
  const context = useWeb3React();
  const {
    connector,
    library,
    chainId,
    account,
    activate,
    deactivate,
    active,
    error
  } = context;

  // handle logic to recognize the connector currently being activated
  const [activatingConnector, setActivatingConnector] = useState();
  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect();

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !!activatingConnector);

  ///////////////////////////////////////////////////////////////////////////////////////////////////



  const BUSD_CONTRACT_ABI = require("./busd-contract-abi.json");
  const BUSD_CONTRACT_ADDRESS = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";

  const MBP_CONTRACT_ABI = require("./mbp-contract-abi.json");
  const MBP_CONTRACT_ADDRESS = "0xaF2F53cc6cc0384aba52275b0f715851Fb5AFf94";
  // const MBP_CONTRACT_ABI = require("./test-token-contract-abi.json");//////////////////TEST
  // const MBP_CONTRACT_ADDRESS = "0x6De450C39f78540E2F5a2Db0aD15CEd7883752dA";/////TEST

  const DKS_CONTRACT_ABI = require("./dks-contract-abi.json");
  const DKS_CONTRACT_ADDRESS = "0x121235cfF4c59EEC80b14c1d38B44e7de3A18287";

  const STAKING_CONTRACT_ABI = require("./staking-contract-abi.json");
  const STAKING_CONTRACT_ADDRESS = "0x51a0d5B27f1E4748372b19D0a303974035A988D2";





  //state variables
  const [status, setStatus] = useState("Welcome to the IDO Page.");
  //for tracking smart contract update so the whole app refresh to broadcast the update
  //since we are not using socket that can listen to smart contract events
  const [smUpdate, setSmUpdate] = useState("");

  const [userMbpBalance, setUserMbpBalance] = useState(0);
  const [userDksBalance, setUserDksBalance] = useState(0);
  const [userUsdBalance, setUserUsdBalance] = useState(0);





  const [idoData, setIdoData] = useState([]);

  const [spinner, setSpinner] = useState(false);


  const [tokenToStake, setTokenToStake] = useState('MBP');
  const [stakeAmount, setStakeAmount] = useState(0);
  const [mbpAllowance, setMbpAllowance] = useState(0);
  const [dksAllowance, setDksAllowance] = useState(0);
  const [stakeState, setStakeState] = useState('STAKE');
  const [userMBPAmountStaked, setUserMBPAmountStaked] = useState(0);
  const [userDKSAmountStaked, setUserDKSAmountStaked] = useState(0);
  const [userMBPStakeDays, setUserMBPStakeDays] = useState(0);
  const [userDKSStakeDays, setUserDKSStakeDays] = useState(0);
  const [minDaysToUnstake, setMinDaysToUnstake] = useState(30);
  const [minMBPStake, setMinMBPStake] = useState(0);
  const [minDKSStake, setMinDKSStake] = useState(0);

  const [totalMBPStaked, setTotalMBPStaked] = useState(0);
  const [totalDKSStaked, setTotalDKSStaked] = useState(0);
  const [mbpPrice, setMbpPrice] = useState(0.012);

  const [noUnstakeDays, setNoUnstakeDays] = useState(10);
  const [unstakeFee1, setUnstakeFee1] = useState(10);
  const [unstakeFee2, setUnstakeFee2] = useState(5);



  // load price Data // called only once

  useEffect(() => {

    axios.get('https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=mobipad')
      .then((res) => {
        setMbpPrice(res.data[0].current_price);
      })
      .catch((err) => {
        console.log("An HTTP error occured");
      })

  }, []);


  //connection dependent calls in conditional useEffect
  useEffect(() => {

    if (library) {

      /**
       * NOW library is the web3 instance
       * NOW ROUTER_CONTRACT is the contract instance
       */
      // Initialise Staking contract
      var STAKING_CONTRACT = new library.eth.Contract(
        STAKING_CONTRACT_ABI,
        STAKING_CONTRACT_ADDRESS
      );

      async function fetchMinDaysToUnstake() {
        const result = await loadMinDaysToUnstake(STAKING_CONTRACT);
        setMinDaysToUnstake(result);
      }

      async function fetchMinMBPStake() {
        const result = await loadMinMBPStake(library, STAKING_CONTRACT);
        setMinMBPStake(result);
      }

      async function fetchMinDKSStake() {
        const result = await loadMinDKSStake(library, STAKING_CONTRACT);
        setMinDKSStake(result);
      }

      async function fetchTotalMBPAmountStaked() {
        const result = await loadTotalMBPAmountStaked(library, STAKING_CONTRACT);
        setTotalMBPStaked(result);
      }

      async function fetchTotalDKSAmountStaked() {
        const result = await loadTotalDKSAmountStaked(library, STAKING_CONTRACT);
        setTotalDKSStaked(result);
      }

      async function fetchNoUnstakeDays() {
        const result = await loadNoUnstakeDays(STAKING_CONTRACT);
        setNoUnstakeDays(result);
      }

      async function fetchUnstakeFee1() {
        const result = await loadUnstakeFee1(STAKING_CONTRACT);
        setUnstakeFee1(result);
      }

      async function fetchUnstakeFee2() {
        const result = await loadUnstakeFee2(STAKING_CONTRACT);
        setUnstakeFee2(result);
      }



      fetchMinDaysToUnstake();
      fetchMinMBPStake();
      fetchMinDKSStake();
      fetchTotalMBPAmountStaked();
      fetchTotalDKSAmountStaked();

      fetchNoUnstakeDays();
      fetchUnstakeFee1();
      fetchUnstakeFee2();
    }

    if (error) {
      const { status } = getErrorMessage(error);
      setStatus(status);
    }

  }, [library, chainId, smUpdate]);


  // wallet dependent calls in conditional useEffect
  useEffect(() => {

    if (library && account) {

      /**
       * NOW library is the web3 instance
       * NOW ROUTER_CONTRACT is the contract instance
       */
      // Initialise BUSD contract
      var BUSD_CONTRACT = new library.eth.Contract(
        BUSD_CONTRACT_ABI,
        BUSD_CONTRACT_ADDRESS
      );
      // Initialise MBP contract
      var MBP_CONTRACT = new library.eth.Contract(
        MBP_CONTRACT_ABI,
        MBP_CONTRACT_ADDRESS
      );
      // Initialise DKS contract
      var DKS_CONTRACT = new library.eth.Contract(
        DKS_CONTRACT_ABI,
        DKS_CONTRACT_ADDRESS
      );
      // Initialise Staking contract
      var STAKING_CONTRACT = new library.eth.Contract(
        STAKING_CONTRACT_ABI,
        STAKING_CONTRACT_ADDRESS
      );


      // async function fetchUserBnbBalance() {
      //   const result = await loadUserBnbBalance(library, account);
      //   setUserBnbBalance(result);
      // }


      async function fetchMBPAllowance() {
        const result = await loadUsdAllowance(library, MBP_CONTRACT, account, STAKING_CONTRACT_ADDRESS);
        setMbpAllowance(parseFloat(result));
      }

      async function fetchDKSAllowance() {
        const result = await loadUsdAllowance(library, DKS_CONTRACT, account, STAKING_CONTRACT_ADDRESS);
        setDksAllowance(parseFloat(result));
      }

      async function fetchUserUsdBalance() {
        const result = await loadUserUsdBalance(library, BUSD_CONTRACT, account);
        setUserUsdBalance(parseFloat(result));
      }

      async function fetchUserMbpBalance() {
        const result = await loadUserMbpBalance(library, MBP_CONTRACT, account);
        setUserMbpBalance(parseFloat(result));
      }

      async function fetchUserDksBalance() {
        const result = await loadUserDksBalance(library, DKS_CONTRACT, account);
        setUserDksBalance(parseFloat(result));
      }

      async function fetchUserMBPAmountStaked() {
        const result = await loadUserMBPAmountStaked(library, STAKING_CONTRACT, account);
        setUserMBPAmountStaked(parseFloat(result));
      }

      async function fetchUserDKSAmountStaked() {
        const result = await loadUserDKSAmountStaked(library, STAKING_CONTRACT, account);
        setUserDKSAmountStaked(parseFloat(result));
      }

      async function fetchUserMBP_StakeDaysCount() {
        const result = await loadUserMBP_StakeDaysCount(STAKING_CONTRACT, account);
        setUserMBPStakeDays(parseFloat(result));
      }

      async function fetchUserDKS_StakeDaysCount() {
        const result = await loadUserDKS_StakeDaysCount(STAKING_CONTRACT, account);
        setUserDKSStakeDays(parseFloat(result));
      }



      fetchMBPAllowance();
      fetchDKSAllowance();
      fetchUserMBPAmountStaked();
      fetchUserDKSAmountStaked();
      fetchUserMBP_StakeDaysCount();
      fetchUserDKS_StakeDaysCount();

      fetchUserUsdBalance();
      fetchUserMbpBalance();
      fetchUserDksBalance();


      if (error) {
        const { status } = getErrorMessage(error);
        setStatus(status);
      }
    }

  }, [library, account, chainId, smUpdate])//add the dependence in array


  // error dependent calls in conditional useEffect
  useEffect(() => {

    const { status } = getErrorMessage(error);
    setStatus(status);

  }, [error]);


  ///////////////////////////////////////////////////////////////////////////////////////////////////
  // log the walletconnect URI
  React.useEffect(() => {
    const logURI = uri => {
      // console.log("WalletConnect URI", uri);
      console.log("WalletConnect URI Available");
    };
    walletconnect.on(URI_AVAILABLE, logURI);

    return () => {
      walletconnect.off(URI_AVAILABLE, logURI);
    };
  }, []);
  ///////////////////////////////////////////////////////////////////////////////////////////////////




  const modalCloseButtonRef = useRef(null);

  const metamaskConnectPressed = async () => { //TODO: implement
    setActivatingConnector(connectorsByName["Injected"]);
    activate(connectorsByName["Injected"]);
    modalCloseButtonRef.current.click();
  };

  const walletconnectConnectPressed = async () => { //TODO: implement
    setActivatingConnector(connectorsByName["WalletConnect"]);
    activate(connectorsByName["WalletConnect"]);
    modalCloseButtonRef.current.click();
  };

  const disconnectWalletPressed = async () => { //TODO: implement
    if (active || error) {
      if (connector !== walletconnect) {//Logging out Metamask
        deactivate();
      } else {//Logging out WalletConnect
        connector.close();
      }
    }
  };


  ///////////////////////////////////////////////////////////////////////////////////////




  const onApproveTokenPressed = async () => { //TODO: implement
    setSpinner(true)
    if (active && library && account) {
      if (tokenToStake === "MBP") {
        // Initialise MBP contract
        var MBP_CONTRACT = new library.eth.Contract(
          MBP_CONTRACT_ABI,
          MBP_CONTRACT_ADDRESS
        );
        const { status, smUpdate } = await approveUsdSpending(library, MBP_CONTRACT, MBP_CONTRACT_ADDRESS, STAKING_CONTRACT_ADDRESS, account, stakeAmount);
        setStatus(status);
        setSmUpdate(smUpdate);
        setMbpAllowance(parseFloat(stakeAmount));
        setSpinner(false)
      }
      if (tokenToStake === "DKS") {
        // Initialise MBP contract
        var DKS_CONTRACT = new library.eth.Contract(
          DKS_CONTRACT_ABI,
          DKS_CONTRACT_ADDRESS
        );
        const { status, smUpdate } = await approveUsdSpending(library, DKS_CONTRACT, DKS_CONTRACT_ADDRESS, STAKING_CONTRACT_ADDRESS, account, stakeAmount);
        setStatus(status);
        setSmUpdate(smUpdate);
        setMbpAllowance(parseFloat(stakeAmount));
        setSpinner(false)
      }
    } else {
      setStatus("You must be connected to the Blockchain.");
      setSpinner(false)
    }

  };

  const onStakeTokenPressed = async () => { //TODO: implement
    setSpinner(true)
    if (active && library && account) {
      // Initialise the contract
      var STAKING_CONTRACT = new library.eth.Contract(
        STAKING_CONTRACT_ABI,
        STAKING_CONTRACT_ADDRESS
      );
      /**
       * NOW library is the web3 instance
       * NOW ROUTER_CONTRACT is the contract instance
       */

      if (tokenToStake === "MBP") {
        const { status, smUpdate } = await stakeMBPToken(library, STAKING_CONTRACT_ADDRESS, STAKING_CONTRACT, account, stakeAmount);
        setStatus(status);
        setSmUpdate(smUpdate);
        setStakeAmount(0);
      }
      if (tokenToStake === "DKS") {
        const { status, smUpdate } = await stakeDKSToken(library, STAKING_CONTRACT_ADDRESS, STAKING_CONTRACT, account, stakeAmount);
        setStatus(status);
        setSmUpdate(smUpdate);
        setStakeAmount(0);
      }

      setSpinner(false)
    } else {
      setStatus("You must be connected to the Blockchain.");
      setSpinner(false)
    }

  };

  const onUnstakeTokenPressed = async () => { //TODO: implement
    setSpinner(true)
    if (active && library && account) {
      // Initialise the contract
      var STAKING_CONTRACT = new library.eth.Contract(
        STAKING_CONTRACT_ABI,
        STAKING_CONTRACT_ADDRESS
      );
      /**
       * NOW library is the web3 instance
       * NOW ROUTER_CONTRACT is the contract instance
       */

      if (tokenToStake === "MBP") {
        const { status, smUpdate } = await unstakeMBPToken(library, STAKING_CONTRACT_ADDRESS, STAKING_CONTRACT, account, stakeAmount);
        setStatus(status);
        setSmUpdate(smUpdate);
        setStakeAmount(0);
      }
      if (tokenToStake === "DKS") {
        const { status, smUpdate } = await unstakeDKSToken(library, STAKING_CONTRACT_ADDRESS, STAKING_CONTRACT, account, stakeAmount);
        setStatus(status);
        setSmUpdate(smUpdate);
        setStakeAmount(0);
      }

      setSpinner(false)
    } else {
      setStatus("You must be connected to the Blockchain.");
      setSpinner(false)
    }

  };



  //the UI of our component
  return (

    <>






      <header className="header">
        <div className="container">
          <div className="d-flex align-items-center">
            <div className="logo">
              <a href="">
                <img src="images/logo.png" alt="" className="d-none d-md-block" />
                <img
                  src="images/logo-mobile.png"
                  alt=""
                  className="d-block d-md-none"
                />
              </a>
            </div>
            <div className="header-right d-flex align-items-center justify-content-center">
              <div
                className="offcanvas offcanvas-start"
                tabIndex={-1}
                id="offcanvasExample"
                aria-labelledby="offcanvasExampleLabel"
              >
                <div className="offcanvas-header d-lg-none justify-content-between p-0">
                  <div className="offcanvas-header-logo">
                    <img src="images/logo-mobile.png" alt="" />
                  </div>
                  <div
                    className="header-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    <i className="bi bi-x-lg" />
                  </div>
                </div>
                <div className="offcanvas-body">
                  <nav className="d-lg-none">
                    <ul className="navbar-nav navmenus">
                      <li className="nav-item">
                        <a href="https://mobipad.io/" className="nav-link">
                          Home
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="https://staking.mobipad.io" className="nav-link">
                          Staking
                        </a>
                      </li>
                    </ul>
                  </nav>
                  <div className="user-auth ms-auto">
                    <div className="d-flex align-items-center">
                      <ul className="d-lg-flex align-items-center">
                        <li>
                          <a href="https://mobipad.io/">
                            <img src="images/icon-home.png" alt="" />
                          </a>
                        </li>
                        <li>
                          {status !== "You're connected to an unsupported network." ?
                            <a href="" className="btn-primary text-uppercase btn-bsc">
                              <img src="images/icon-bsc.png" alt="" /> BSC
                            </a>
                            :
                            <a href="" className="btn-primary text-danger btn-bsc">
                              Select BSC Chain
                            </a>
                          }
                        </li>


                        <li>
                          {(!active) && (
                            <button
                              className="btn-primary text-capitalize btn-connect"
                              style={{ marginBottom: 8 }}
                              data-bs-toggle="modal"
                              data-bs-target="#connectModal"
                            >
                              <img src="images/icon-connect.png" alt="" /> Connect
                            </button>
                          )}

                          {(active && account && connector) &&
                            <button
                              className="btn-primary text-capitalize btn-connect"
                              style={{ padding: 10, marginBottom: 8 }}
                            >
                              <img src={connector !== walletconnect ? "images/MetaMask_Fox.png" : "images/WalletConnect-icon.png"} alt=""></img>
                              {account.substring(0, 5) + "..." + account.substring(38)}
                            </button>
                          }
                          {(active) && (
                            <button
                              className="btn text-capitalize btn-connect"
                              id="logoutButton"
                              onClick={disconnectWalletPressed}
                              style={{ padding: 5, color: "white" }}
                            >
                              <i className="fas fa-sign-out-alt" />
                            </button>
                          )}
                        </li>


                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              className="btn d-lg-none bg-transparent border-0 p-0"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasExample"
              aria-controls="offcanvasExample"
            >
              <span className="mobile-nav">
                <img src="images/menu.svg" alt="" />
              </span>
            </button>
          </div>
        </div>
      </header>




      <main className="main staking-page">
        <section className="sec-staking">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="staking-main">
                  <div className="staking-head">
                    <h2 className="text-center head-main">STAKING</h2>
                    <p className="mt-10 text-mbp text-uppercase">
                      MBP Staking for IDO projects on MobiPad Platform
                    </p>
                    <p>
                      Still staking on the old version? unstake from there and stake the MBP here.
                      Previous version - <a href={"https://stakingv1.mobipad.io"}>Staking v1</a>.
                    </p>
                  </div>
                  <div className="wallet-block">
                    <div className="wallet-main">
                      <div className="bg-black ">

                        <div className="mt-32">

                          <div className="unstake mb-4">
                            <div className="mob-tvl mb-4">
                              <h4 className="text-20 text-white">
                                <span className="price-none">$ {(totalMBPStaked * mbpPrice).toLocaleString()}</span>
                                <span className="tvl">TVL</span>
                                <div className="question-mark">
                                  <a href="#">
                                    <i className="bi bi-question" />
                                  </a>
                                  <div className="question-tooltip">
                                    <p>
                                      Total Value Locked (TVL) represents the dollar value of
                                      cryptocurrencies currently locked up on the staking smart contract.
                                    </p>
                                  </div>
                                </div>
                              </h4>
                            </div>
                          </div>


                          <div className="unstake price-input mb-4 sec-unstake-bg">
                            <div className="unstake-flex">


                              <div className="staked">
                                <p className="text-13 text-you-have">
                                  Your MBP Stake
                                </p>
                                <div className="mb-3 color-blue mbp-tooltip">
                                  {userMBPAmountStaked} MBP
                                  <div className="blue-tooltip">
                                    <p className="text-13 m-0">{userMBPAmountStaked} MBP</p>
                                  </div>
                                </div>
                              </div>


                              <div className="staked">
                                <p className="text-13 text-you-have">
                                  Days
                                </p>
                                <div className="mb-3 color-blue mbp-tooltip">
                                  {userMBPStakeDays} Days
                                  <div className="blue-tooltip">
                                    <p className="text-13 m-0">{userMBPStakeDays} Days</p>
                                  </div>
                                </div>
                              </div>


                            </div>
                          </div>


                          <div className="mbp-count mb-4">
                            <div className="bg-inner-dark">
                              <div className="share-pool">
                                <div className="price-input">
                                  <select
                                    className="form-select bg-inner-dark"
                                    value={stakeState}
                                    onChange={(e) => setStakeState(e.target.value)}
                                    style={{ backgroundColor: "#121417", color: "#949494", border: "1px solid #949494" }}
                                  >
                                    <option value="STAKE">Stake</option>
                                    <option value="UNSTAKE">Unstake</option>
                                  </select>

                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="mbp-count">
                            <div className="bg-inner-dark">
                              <div className="share-pool">
                                <div className="price-input">
                                  <select
                                    className="form-select bg-inner-dark"
                                    style={{ backgroundColor: "#121417", color: "#949494", border: "1px solid #949494" }}
                                    value={tokenToStake}
                                    onChange={(e) => setTokenToStake(e.target.value)}
                                  >
                                    <option value="MBP">MBP</option>
                                    {/* <option value="DKS">DKS</option> */}
                                  </select>

                                </div>
                              </div>
                            </div>
                          </div>



                          <div className="mt-32 enter-amount">
                            <div className="share-pool">
                              <p className="text-white">Enter Token Amount</p>

                              <div className="text-13 color-grey balance">
                                Balance{" "}
                                <span className="color-lead-blue"> {userMbpBalance} MBP</span>
                                <div className="amount blue-tooltip">
                                  <p className="color-lead-blue">
                                    {userMbpBalance} MBP
                                  </p>
                                </div>
                              </div>

                            </div>
                          </div>

                          <div className="mbp-count">
                            <div className="bg-inner-dark">
                              <div className="share-pool">
                                <div className="price-input">

                                  <input
                                    id="number"
                                    type="text"
                                    // className="form-control"
                                    placeholder="Amount"
                                    onChange={(e) => setStakeAmount(e.target.value)}
                                    value={stakeAmount}
                                  />

                                  <p
                                    className="color-blue"
                                    onClick={(e) => setStakeAmount(userMbpBalance)}
                                  >
                                    MAX
                                  </p>

                                </div>
                              </div>
                            </div>
                          </div>



                          {(stakeState === "STAKE" && tokenToStake === "MBP" && stakeAmount > userMbpBalance) &&
                            <>
                              <div className="col-md-12 my-3 text-center">
                                <small className="text-danger">
                                  *** Insufficient MBP Balance
                                </small>
                              </div>
                            </>
                          }
                          {(tokenToStake === "MBP" && stakeAmount > 0 && stakeAmount < userMbpBalance && stakeAmount < minMBPStake) &&
                            <>
                              <div className="col-md-12 my-3 text-center">
                                <small className="text-danger">
                                  *** Minimum allowed is {minMBPStake} MBP
                                </small>
                              </div>
                            </>
                          }
                          {(stakeState === "STAKE" && tokenToStake === "DKS" && stakeAmount > userDksBalance) &&
                            <>
                              <div className="col-md-12 my-3 text-center">
                                <small className="text-danger">
                                  *** Insufficient DKS Balance
                                </small>
                              </div>
                            </>
                          }
                          {(tokenToStake === "DKS" && stakeAmount > 0 && stakeAmount < userDksBalance && stakeAmount < minDKSStake) &&
                            <>
                              <div className="col-md-12 my-3 text-center">
                                <small className="text-danger">
                                  *** Minimum allowed is {minDKSStake} DKS
                                </small>
                              </div>
                            </>
                          }


                          {(spinner) &&
                            <div className="col-md-12 mb-3 mt-4">
                              <div>
                                <button href="" className="btn w-100 btn-info">
                                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                  &nbsp;
                                  Please Wait...
                                </button>
                              </div>
                            </div>
                          }


                          {/* <div className="mt-28">
                            <div className="connect-wallet text-center mt-20">
                              <a
                                href="#"
                                className="btn-primary btn-fill res-btn text-uppercase"
                                data-bs-toggle="modal"
                                data-bs-target="#unstakeModal"
                              >
                                Stake
                              </a>
                            </div>
                          </div> */}

                          <div className="col-md-12 mb-3 mt-4">
                            <div className="row">


                              {(active && account && !spinner) && (
                                <>
                                  {(stakeState === "STAKE" && tokenToStake === "MBP" && stakeAmount > 0 && mbpAllowance < stakeAmount) &&
                                    <div className="col">
                                      <button
                                        className="btn w-100 btn-info"
                                        id="approveMbp"
                                        onClick={onApproveTokenPressed}
                                        disabled={spinner}
                                      >
                                        Approve MBP
                                      </button>
                                    </div>
                                  }
                                  {(stakeState === "STAKE" && tokenToStake === "DKS" && stakeAmount > 0 && dksAllowance < stakeAmount) &&
                                    <div className="col">
                                      <button
                                        className="btn w-100 btn-info"
                                        id="approveDks"
                                        onClick={onApproveTokenPressed}
                                        disabled={spinner}
                                      >
                                        Approve DKS
                                      </button>
                                    </div>
                                  }
                                </>
                              )}




                              {(stakeState === "STAKE" && active && account && !spinner) && (<>
                                <div className="col">
                                  <button
                                    className="btn btn-info w-100"
                                    id="stakeTokensButton"
                                    onClick={onStakeTokenPressed}
                                    disabled={spinner}
                                  >
                                    STAKE
                                  </button>
                                </div>
                              </>)}


                              {(stakeState === "UNSTAKE" && userMBPAmountStaked > 0 && userMBPStakeDays <= noUnstakeDays) &&
                                <>
                                  <div className="col-md-12 my-3 text-center">
                                    <small className="text-danger">
                                      You can only Unstake after {noUnstakeDays} days of staking. Unstaking before {minDaysToUnstake} days
                                      attract a fee.
                                    </small>
                                  </div>
                                </>
                              }
                              {(stakeState === "UNSTAKE" && userMBPAmountStaked > 0 && userMBPStakeDays > noUnstakeDays
                                && userMBPStakeDays <= ((minDaysToUnstake - noUnstakeDays) / 2) * 2) &&
                                <>
                                  <div className="col-md-12 my-3 text-center">
                                    <small className="text-danger">
                                      You will be charged {unstakeFee1}% of your unstake amount for unstaking
                                      before {minDaysToUnstake} days of staking.
                                    </small>
                                  </div>
                                </>
                              }
                              {(stakeState === "UNSTAKE" && userMBPAmountStaked > 0 && userMBPStakeDays > ((minDaysToUnstake - noUnstakeDays) / 2) * 2
                                && userMBPStakeDays <= minDaysToUnstake) &&
                                <>
                                  <div className="col-md-12 my-3 text-center">
                                    <small className="text-danger">
                                      You will be charged {unstakeFee2}% of your unstake amount for unstaking
                                      before {minDaysToUnstake} days of staking.
                                    </small>
                                  </div>
                                </>
                              }


                              {(stakeState === "UNSTAKE" && active && account && !spinner) && (<>
                                <div className="col">
                                  <button
                                    className="btn btn-info w-100"
                                    id="unstakeTokensButton"
                                    onClick={onUnstakeTokenPressed}
                                    disabled={spinner}
                                  >
                                    UNSTAKE
                                  </button>
                                </div>
                              </>)}


                            </div>
                          </div>

                          <div className="col-md-12 mb-3 mt-5 text-center">
                            <p>
                              {status}
                            </p>
                          </div>


                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sec-faq">
          <div className="container">
            <div className="faq-head">
              <h3 className="text-32">FAQ</h3>
              <div className="faq">
                <div className="faq-inner">
                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          Why Stake?
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          Staking grants MobiPad users the chance to invest on IDO projects on the MobiPad Platform.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          Minimum MBP stake and unstake
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          Current minimum MBP allowed is {minMBPStake} MBP for both staking and unstaking
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          Unstake
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          Users can only unstake after {noUnstakeDays} days of staking, no user is allowed to do so before {noUnstakeDays} days.
                          <br></br><br></br>
                          You will be charged {unstakeFee1}% of your unstake amount for unstaking
                          from {(noUnstakeDays * 1) + 1} days to {((minDaysToUnstake - noUnstakeDays) / 2) * 2} days of staking.
                          <br></br><br></br>
                          You will be charged {unstakeFee2}% of your unstake amount for unstaking
                          from {((minDaysToUnstake - noUnstakeDays) / 2) * 2 + 1} days to {minDaysToUnstake} days of staking.
                          <br></br><br></br>
                          There is NO fee on unstaking after {minDaysToUnstake} days of staking.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFour">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          Previous Stake Page
                        </button>
                      </h2>
                      <div
                        id="collapseFour"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFour"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          This the the latest MobiPad staking app, you must stake here to be eligible to participate in the MobiPad IDOs.
                          So if you are still staking on the previous version please unstake from there and stake the MBP here. You can
                          access the previous version here <a href={"https://stakingv1.mobipad.io"}>Staking v1</a>.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>





      <>
        {/* Modal */}
        {/* connect modal  */}
        <div
          className="modal fade wallet-modal "
          id="connectModal"
          tabIndex={-1}
          aria-labelledby="connectModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-inner">
                <div className="modal-content">
                  <button
                    type="button"
                    className="modal-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    ref={modalCloseButtonRef}
                  >
                    <img src="images/close.png" alt="" />
                  </button>
                  <div className="modal-body">
                    <div className="connect-wallet-modal">
                      <h3 className="connect-walletText text-center text-uppercase">
                        Connect wallet
                      </h3>
                    </div>
                    <div className="wallet-inner text-center">
                      <p>Choose a provider to connect wallet </p>
                      <div className="wallet-btns">
                        <div className="metamask">
                          <p
                            className="metamask-btn"
                            id="MetaMaskButton"
                            onClick={metamaskConnectPressed}
                            style={{ cursor: "pointer" }}
                          >
                            {" "}
                            <img src="images/MetaMask_Fox.png" alt="" />
                            MetaMask
                          </p>

                        </div>
                        <div className="metamask mt-20">
                          <p
                            className="metamask-btn"
                            id="WalletConnect"
                            onClick={walletconnectConnectPressed}
                            style={{ cursor: "pointer" }}
                          >
                            {" "}
                            <img src="images/WalletConnect-icon.png" alt="" />{" "}
                            WalletConnect
                          </p>
                        </div>

                        {/* <div className="metamask mt-20">
                          <a href="#" className="metamask-btn">
                            {" "}
                            <img src="images/Coinbase-logo-square.png" alt="" />{" "}
                            Coinbase
                          </a>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>

















    </>

  );
};

export default AppEngine;
